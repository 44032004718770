<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Persediaan Kantung <b>Darah</b>
      </div>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <b-table
              striped
              hover
              :items="items"
              :fields="fields"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '33%' : '' }"
                />
              </template>
              <template #cell(actions)="data">
                <!-- <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  @click="$router.push({ path: '/blood-bank/detail/1' })"
                > Info </b-button> -->
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="setModal(data.item.id)"
                > <i class="fas fa-edit px-0"></i> </b-button>
              </template>
            </b-table>
          </template>
        </Card>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
      ref="my-modal"
      hide-footer
      title="Resep Obat"
    >
      <b-form @submit.stop.prevent="formOnSubmit">
        <!-- <div class="d-block text-center"> -->
        <b-form-group
          id="input-group-blood-type"
          label="Nama:"
          label-for="input-blood-type"
        >
          <b-form-input
            id="input-blood-type"
            v-model="form.blood_type_name"
            placeholder="Nama obat"
            readonly
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-quantity"
          label="Jumlah Kantong Darah:"
          label-for="input-quantity"
        >
          <b-form-input
            id="input-quantity"
            v-model="form.quantity"
            placeholder="Jumlah Kantong Darah"
          ></b-form-input>
        </b-form-group>
        <!-- </div> -->
        <!-- Submit & Cancel button -->
        <b-button
          type="submit"
          variant="primary"
        >Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="hideModal"
        >
          Cancel
        </b-button>
      </b-form>
    </b-modal>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import module from "@/core/modules/CrudModule.js";
// import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card
  },

  data() {
    return {
      form: {
        blood_type_id: null,
        blood_type_name: "",
        quantity: null
      },
      blood_bank_id: null,
      fields: [
        {
          key: 'number',
          label: '#',
          sortable: true,
        },
        {
          key: 'blood_type_name',
          label: 'Golongan Darah',
          sortable: true
        },
        {
          key: 'quantity',
          label: 'Jumlah Kantung',
          sortable: true
        },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      // Form Route
      route: 'blood-banks',
    }
  },

  methods: {
    async list() {
      this.items = await module.list("blood-banks");
      this.form['_method'] = 'put'
    },

    async setModal(id) {
      this.data = await module.get('blood-banks/' + id)
      this.form.blood_type_name = this.data.blood_type_name
      this.form.blood_type_id = this.data.blood_type_id
      this.form.quantity = this.data.quantity
      this.blood_bank_id = this.data.id
      this.$refs["my-modal"].show();

    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("blood-banks/" + id);
      // If Deleted
      if (result) {
        this.list();
      }
    },

    async formOnSubmit() {      
      let response = await module.submit(this.form, `${this.route}/${this.blood_bank_id}`)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        // Hide Modal
        this.hideModal()
        this.list()
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Bank Darah" },
    ])

    this.list()
  },

}
</script>

<style>
</style>